import React, { useState, useEffect } from "react";
import { ArrowLeftIcon } from "@heroicons/react/20/solid";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { LeftPanelShimmer, RightPanelShimmer } from "./utilities/Shimmer";
import "./css/base.css";
import parse from "html-react-parser";
import Card from "@mui/material/Card";

function Base() {
    const [merchantName, setMerchantName] = useState("");
    const [fundraiserTitle, setFundraiserTitle] =
        useState("Please Support Us!");
    const [formID, setFormID] = useState(null);
    const [fetchError, setFetchError] = useState(false);

    const [loadedForm, setLoadedForm] = useState(false);

    const [loadedEventDetails, setLoadedEventDetails] = useState(false);
    const [eventDetails, setEventDetails] = useState(null);

    const [fullscreen, setFullscreen] = useState(true);
    const [imageError, setImageError] = useState(false);

    const [currentPage, setCurrentPage] = useState("START_PAGE");

    const fetchPageConfig = async () => {
        // Get the current endpoint (path) from the URL
        // this is the pageID which is also the formID
        const pageID = window.location.pathname.replace("/", "");

        // Now, you can use the currentEndpoint variable for your purposes
        console.log("Current Endpoint:", pageID);

        // Fetch the page config from the CharityStack API
        const response = await fetch(
            `${process.env.REACT_APP_ELEMENTS_BACKEND_URL}/donation-page?pageID=${pageID}`
        );

        // If the page config is not found, show a 404 page
        if (!response.ok) {
            console.log("hello");
            setFetchError(true);
            return;
        }

        const data = await response.json();

        document.title = data.formName + " | CharityStack";

        // set fetch error to true if data is null
        if (data == null) {
            setFetchError(true);
            return;
        }
        console.log("data", data);
        setMerchantName(data.nonprofitName);
        setFundraiserTitle(data.formName);

        if (
            data.eventDetails == null ||
            data.eventDetails == undefined ||
            data.eventDetails == ""
        ) {
            setLoadedEventDetails(true);
        }
        setFormID(pageID);
        setEventDetails(data.eventDetails ?? null);
    };

    // Mount the hosted script when the formID is set to prevent
    // race conditions
    useEffect(() => {
        if (formID !== null) {
            const urlParams = new URLSearchParams(window.location.search);
            const popup = urlParams.get("popup");

            const script = document.createElement("script");
            script.src =
                process.env.REACT_APP_CS_JS_SCRIPT +
                (popup === "true" ? "?popup=true" : "");
            // append script to head
            document.head.appendChild(script);
        }
    }, [formID]);

    const exitPopup = () => {
        window.parent.postMessage(
            {
                action: "EXIT_CHECKOUT_FORM",
                entity: "CHARITYSTACK",
                sender: "CHECKOUT_FORM",
                receiver: "HOSTED_SCRIPT",
                data: {
                    donationSuccessful: false,
                    elementID: formID,
                    elementType: "EVENT",
                    exitType: "EXIT",
                },
            },
            "*"
        );
    };

    const resetFormListener = (event) => {
        if (
            !(
                event.data.entity == "CHARITYSTACK" &&
                event.data.action == "RESET_ELEMENT" &&
                event.data.receiver == "HOSTED_PAGE"
            )
        )
            return;
        // use pageID to get element
        const pageID = window.location.pathname.replace("/", "");
        const formElement = document.getElementById(pageID);

        if (formElement == null) return;
        formElement.contentWindow.postMessage(
            {
                action: "RESET_ELEMENT",
                entity: "CHARITYSTACK",
                sender: "HOSTED_PAGE",
                receiver: "EVENT",
                data: {
                    donationSuccessful: event.data.data.donationSuccessful,
                    elementID: event.data.data.elementID,
                },
            },
            "*"
        );
    };

    const exitPopupListener = (event) => {
        if (
            !(
                event.data.entity == "CHARITYSTACK" &&
                event.data.action == "EXIT_HOSTED_PAGE_POPUP"
            )
        )
            return;
        console.log("EXIT_HOSTED_PAGE_POPUP", event);

        //  const sender = event.data.sender;
        //  if (sender === "DONOR_LIST") {
        //    setLoadedDonorList(true);
        //  }
        //  if (sender === "FUNDRAISING_BAR") {
        //    setLoadedFundraisingBar(true);
        //  }
        //  if (
        //    sender === "CROWDFUNDING_FORM" ||
        //    sender === "STANDARD_FORM" ||
        //    sender === "QUICK_DONATE"
        //  ) {
        //    setLoadedForm(true);
        //  }
    };

    const updateLoadingListener = (event) => {
        if (
            !(
                event.data.entity == "CHARITYSTACK" &&
                event.data.action == "FINISHED_LOADING"
            )
        )
            return;
        console.log("updateLoadingListener", event);

        const sender = event.data.sender;
        if (sender === "EVENT") {
            setLoadedForm(true);
            console.log("set setLoadedForm to true");
        }
    };

    const updateCurrentPageListener = (event) => {
        if (
            !(
                event.data.entity == "CHARITYSTACK" &&
                event.data.action == "CHANGE_PAGE"
            )
        )
            return;
        const data = JSON.parse(event.data.data);
        console.log(data.currentPage);
        setCurrentPage(data.currentPage);
    };

    useEffect(() => {
        // grab url parameter called popup
        const urlParams = new URLSearchParams(window.location.search);
        const popup = urlParams.get("popup");
        if (popup === "true") {
            setFullscreen(false);
        }

        window.addEventListener("message", updateCurrentPageListener);
        window.addEventListener("message", updateLoadingListener);
        window.addEventListener("message", resetFormListener);
        fetchPageConfig();
        return () => {
            window.removeEventListener("message", updateCurrentPageListener);
            window.removeEventListener("message", updateLoadingListener);
        };
    }, []);

    const goBack = () => {
        if (currentPage == "START_PAGE") {
            // go back to last history in browser
            window.history.back();
        } else {
            // go back to last page in CharityStack Form
            const formIframe = document.getElementById(formID);
            formIframe.contentWindow.postMessage(
                {
                    entity: "CHARITYSTACK",
                    sender: "HOSTED_PAGE",
                    action: "GO_BACK",
                },
                "*"
            );
        }
    };

    // Have to use this method b/c iframes won't render if they start
    // off hidden
    // for mobile screens, ensure that parentStyle goes behind not under the loading overlay
    // also make the background colors transparent
    let parentStyle = {
        visibility: "hidden",
        zIndex: "-1",
        position: "absolute",
        top: "0",
        left: "0",
        width: "100%",
        height: "100%",
        backgroundColor: "transparent",
    };
    if (loadedForm) {
        parentStyle = {};
    }

    console.log("merchantName", process.env.REACT_APP_IMAGE_URL);

    if (fetchError) {
        return (
            // return a 404 page
            <div className="flex h-screen items-center justify-center bg-gray-100">
                <div className="rounded-lg bg-white p-8 shadow-md">
                    <h1 className="mb-4 text-4xl font-bold text-gray-800">
                        404 - Page Not Found
                    </h1>
                    <p className="mb-4 text-lg text-gray-600">
                        Sorry, the hosted page you are looking for does not
                        exist.
                    </p>
                    <div>
                        <p className="text-sm text-gray-600">
                            Double check the URL you are accessing is correct.
                        </p>
                        <p className="mb-4 flex text-sm text-gray-600">
                            <div>Please visit</div>
                            <a
                                href="https://dashboard.charitystack.com"
                                className="mx-1 text-blue-500">
                                dashboard.charitystack.com
                            </a>
                            <div>to confirm.</div>
                        </p>
                    </div>
                </div>
            </div>
        );
    }
    return (
        <div
            className={`${
                fullscreen
                    ? ""
                    : "mx-auto my-auto h-auto sm:flex sm:w-full sm:min-w-[300px] sm:max-w-[840px] sm:items-center sm:justify-center sm:p-0"
            }`}>
            <meta
                name="viewport"
                content="width=device-width, minimum-scale=1.0, maximum-scale = 1.0, user-scalable = no"
            />
            <div className="relative">
                {/* Loading overlay */}
                {!loadedForm && (
                    <div
                        className={`${
                            fullscreen
                                ? ""
                                : "sm:flex sm:h-screen sm:w-[1000px] sm:flex-col"
                        }`}>
                        <div
                            className={`flex h-full flex-col sm:flex-row ${
                                fullscreen
                                    ? "overflow-y-auto sm:h-screen"
                                    : "sm:my-auto sm:h-[840px] sm:max-h-[840px] sm:rounded-md sm:border sm:border-gray-400 sm:bg-white"
                            }`}>
                            <div
                                className={`sm:margin-y-auto flex flex-col items-center justify-center bg-gray-100 p-2 sm:p-5 ${
                                    fullscreen
                                        ? "sm:w-7/12 sm:overflow-y-auto"
                                        : "sm:w-1/2"
                                }`}>
                                <div className="flex w-full items-center justify-center">
                                    <LeftPanelShimmer />
                                </div>
                                {/* this is a spacer to push visit charitystack logo to the bottom */}
                                {/* <div className="hidden grow sm:flex"></div>{" "} */}
                                {/* this is the visit charitystack logo */}
                                {/* <div
                className="mb-0 mt-4 grid hidden sm:flex sm:pl-2"
                style={{
                  justifyContent: "center",
                  alignItems: "baseline",
                }}
                onClick={() =>
                  window.open("https://charitystack.com", "_blank")
                }
              >
                <p className="charitystack">powered by</p>
                <p className="charity">charity</p>
                <p className="stack">stack</p>
              </div> */}
                            </div>
                            <div
                                className={`flex flex-1 items-center justify-center bg-white p-2 text-center sm:h-full sm:flex-1 sm:p-5 ${
                                    fullscreen
                                        ? "sm:w-5/12"
                                        : "sm:my-auto sm:w-1/2"
                                }`}>
                                <div className="flex w-full items-center justify-center">
                                    <RightPanelShimmer />
                                </div>
                            </div>
                        </div>
                        {/* logo for small/mobile screens */}
                        <div className="block bg-gray-100 px-5 sm:hidden">
                            <div className="flex grow sm:hidden"></div>{" "}
                            {/* this is a spacer to push logo to the bottom */}
                            <div
                                className="mt-4 flex sm:hidden"
                                style={{
                                    justifyContent: "center",
                                    alignItems: "baseline",
                                    paddingBottom: "12px",
                                }}
                                onClick={() =>
                                    window.open(
                                        "https://charitystack.com",
                                        "_blank"
                                    )
                                }>
                                <p className="charitystack">powered by</p>
                                <p className="charity">charity</p>
                                <p className="stack">stack</p>
                            </div>
                        </div>
                    </div>
                )}

                <div
                    style={parentStyle}
                    className={`${
                        fullscreen ? "" : "sm:flex sm:h-screen sm:w-[1000px]"
                    }`}>
                    {!fullscreen && (
                        <div className="fixed right-0 top-0 hidden p-4 sm:block">
                            <XMarkIcon
                                className="h-10 w-10 cursor-pointer justify-end text-gray-500 text-white"
                                onClick={exitPopup}
                            />
                        </div>
                    )}
                    {/* <meta
            name="viewport"
            content="width=device-width, minimum-scale=1.0, maximum-scale = 1.0, user-scalable = no"
          /> */}
                    <div
                        className={`flex h-full flex-col overflow-y-auto sm:flex-row sm:overflow-hidden ${
                            fullscreen
                                ? "sm:h-screen"
                                : "min-h-screen w-full sm:my-auto sm:h-[550px] sm:max-h-[840px] sm:min-h-[0] sm:rounded-md sm:border sm:border-gray-400 sm:bg-white"
                        }`}>
                        <div
                            className={`no-scrollbar flex flex-col bg-gray-100 p-5 sm:items-center sm:overflow-y-auto sm:py-5 ${
                                fullscreen
                                    ? "sm:w-7/12 sm:px-20"
                                    : "sm:w-1/2 sm:px-5"
                            }`}>
                            <div
                                className={`w-full sm:my-auto sm:items-center sm:justify-center ${
                                    fullscreen ? "sm:flex" : ""
                                }`}>
                                <div
                                    className={`flex justify-between sm:left-0 sm:top-0 sm:self-start ${
                                        fullscreen
                                            ? "sm:absolute sm:p-5"
                                            : "sm:relative"
                                    }`}>
                                    {/* only show back button when there is history to go back to for first page*/}
                                    {/* we also dont want it to show for the first page of popup hosted form */}
                                    {!(
                                        !fullscreen &&
                                        currentPage === "START_PAGE"
                                    ) &&
                                    !(
                                        window.history.length <= 1 &&
                                        currentPage === "START_PAGE"
                                    ) ? (
                                        <button
                                            className={
                                                "block flex cursor-pointer text-gray-500"
                                            }
                                            onClick={goBack}>
                                            <ArrowLeftIcon className="h-5 w-5" />
                                            <div className="text-sm font-medium">
                                                Back
                                            </div>
                                        </button>
                                    ) : fullscreen ? (
                                        <div></div>
                                    ) : (
                                        // only show close button when in popup mode first page
                                        <button
                                            className={
                                                "block flex cursor-pointer text-gray-500 sm:hidden"
                                            }
                                            onClick={exitPopup}>
                                            <XMarkIcon className="h-5 w-5" />
                                        </button>
                                    )}

                                    {/* Show right logo when mobile */}
                                    <img
                                        onError={(e) => {
                                            e.target.src =
                                                "https://uploads-ssl.webflow.com/6413d8e98a61430814f20277/641647a1a3139eaf06e8594c_CharityStack%20Logo%20Full-p-500.png";
                                        }}
                                        src={`${
                                            process.env.REACT_APP_S3_LOGO_URL
                                        }/${encodeURIComponent(
                                            merchantName
                                        )}_Logo.png?timestamp=${Date.now().toString()}`}
                                        // src={
                                        //   "https://uploads-ssl.webflow.com/6413d8e98a61430814f20277/641647a1a3139eaf06e8594c_CharityStack%20Logo%20Full-p-500.png"
                                        // }
                                        className="LogoForOrg block pl-1 sm:hidden sm:pl-0"
                                        style={{
                                            maxWidth: "125px",
                                            maxHeight: "80px",
                                        }}></img>
                                </div>
                                <div className="flex w-full max-w-[1024px] flex-col items-center justify-center">
                                    <>
                                        {/* Show centered logo when desktop */}
                                        <div className="grid">
                                            <img
                                                onError={(e) => {
                                                    e.target.src =
                                                        "https://uploads-ssl.webflow.com/6413d8e98a61430814f20277/641647a1a3139eaf06e8594c_CharityStack%20Logo%20Full-p-500.png";
                                                }}
                                                src={`${
                                                    process.env
                                                        .REACT_APP_S3_LOGO_URL
                                                }/${encodeURIComponent(
                                                    merchantName
                                                )}_Logo.png?timestamp=${Date.now().toString()}`}
                                                // src={
                                                //   "https://uploads-ssl.webflow.com/6413d8e98a61430814f20277/641647a1a3139eaf06e8594c_CharityStack%20Logo%20Full-p-500.png"
                                                // }
                                                className="LogoForOrg hidden justify-self-center pl-1 text-center sm:block sm:pl-0"
                                                style={{
                                                    maxWidth: fullscreen
                                                        ? "225px"
                                                        : "125px",
                                                    maxHeight: fullscreen
                                                        ? "144px"
                                                        : "80px",
                                                }}></img>
                                        </div>
                                        {/* Header text */}
                                        <div className="grid">
                                            <div
                                                className={`justify-self-center pb-4 pt-4 text-center font-serif text-lg sm:pt-8 sm:font-normal ${
                                                    fullscreen
                                                        ? "sm:text-4xl"
                                                        : "sm:text-lg"
                                                }`}>
                                                {fundraiserTitle}
                                            </div>
                                        </div>
                                        {/* Image goes here */}
                                        {!imageError && merchantName !== "" && (
                                            <img
                                                onError={(e) => {
                                                    setImageError(true);
                                                }}
                                                src={`${
                                                    process.env
                                                        .REACT_APP_IMAGE_URL
                                                }/${merchantName}_${formID}.png?timestamp=${Date.now().toString()}`}
                                                // className={`h-48 w-full rounded-md
                                                // ${fullscreen ? "sm:h-[512px]" : "sm:h-[256px]"}`
                                                // }
                                                className="h-auto w-full rounded-md"
                                                style={{
                                                    objectPosition:
                                                        "center top",
                                                    objectFit: "cover",
                                                }}></img>
                                        )}
                                        {eventDetails !== null &&
                                            eventDetails !== "" && (
                                                <div className="w-full ">
                                                    <div
                                                        data-entity="EVENT_DETAILS"
                                                        data-page={
                                                            fullscreen
                                                                ? "HOSTED_PAGE_FULLSCREEN"
                                                                : "HOSTED_PAGE_POPUP"
                                                        }
                                                        data-source="CharityStack">
                                                        <Card
                                                            className="rounded-md border border-gray-200"
                                                            sx={{
                                                                width: "full",
                                                                padding: "20px",
                                                                marginTop:
                                                                    "20px",
                                                                borderWidth:
                                                                    "0.5",
                                                                marginBottom:
                                                                    "20px",
                                                                boxShadow:
                                                                    "0 0 0 0",
                                                                borderRadius:
                                                                    "6px",
                                                            }}>
                                                            {parse(
                                                                eventDetails
                                                            )}
                                                        </Card>
                                                    </div>
                                                </div>
                                            )}
                                    </>

                                    <div></div>
                                </div>
                            </div>
                        </div>
                        <div
                            className={`flex flex-col overflow-y-auto bg-white p-2 text-center sm:w-5/12 sm:p-5 ${
                                fullscreen ? "sm:relative" : "sm:relative"
                            }`}>
                            {/* <div className=""></div> */}
                            <div className="my-auto w-full items-center justify-center">
                                {formID !== null && (
                                    <div
                                        data-entity="EVENT"
                                        data-page={
                                            fullscreen
                                                ? "HOSTED_PAGE_FULLSCREEN"
                                                : "HOSTED_PAGE_POPUP"
                                        }
                                        data-source="CharityStack"
                                        id={
                                            formID === null ? "" : formID
                                        }></div>
                                )}
                            </div>
                            {/* this is the visit charitystack logo on Desktop */}
                            <div
                                className={`hidden self-center justify-self-end sm:flex`}
                                // style={{
                                //     justifyContent: "center",
                                //     alignItems: "baseline",
                                // }}
                                onClick={() =>
                                    window.open(
                                        "https://charitystack.com",
                                        "_blank"
                                    )
                                }>
                                <p className="charitystack">powered by</p>
                                <p className="charity">charity</p>
                                <p className="stack">stack</p>
                            </div>
                        </div>
                        {/* logo for small/mobile screens */}
                        <div className="block bg-white px-5 sm:hidden">
                            <div className="flex grow sm:hidden"></div>{" "}
                            {/* this is a spacer to push logo to the bottom */}
                            <div
                                className="mb-4 mt-6 flex sm:hidden"
                                style={{
                                    justifyContent: "center",
                                    alignItems: "baseline",
                                    paddingBottom: "12px",
                                }}
                                onClick={() =>
                                    window.open(
                                        "https://charitystack.com",
                                        "_blank"
                                    )
                                }>
                                <p className="charitystack">powered by</p>
                                <p className="charity">charity</p>
                                <p className="stack">stack</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Base;
