import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export const LeftPanelShimmer = () => {
  return (
    <div className="w-full max-w-[1024px] p-2 sm:p-5">
      <div className="pb-4">
        <Skeleton className="h-28" />
      </div>
      <div>
        <Skeleton className="h-96" />
      </div>
      <div>
        <Skeleton className="h-[52px]" />
      </div>
      <div>
        <Skeleton className="h-[195px] sm:h-[250px]" />
      </div>
    </div>
  );
};

export const RightPanelShimmer = () => {
  return (
    <div className="w-full max-w-sm">
      <Skeleton height={400} />
    </div>
  );
};
